import AxiosAdapter from "./AxiosAdapter";
import { asthmaReportPatchSerialize, asthmaReportSerialize } from "@/utils/asthmaReportUtil";
import { TokenType } from "./constants";
import { FilterMatchMode } from 'primevue/api';

export default class APIV2Adapter extends AxiosAdapter {
  /**
   * The two-factor token is stored here only temporarily.
   *
   * The actual token is stored via the nuxt-auth module. See the login.vue.
   */
  twoFactorToken = undefined;

  login (username, password) {
    return this.POST('/gateway/services/auth-service/authenticate', {
      username, password
    }, undefined, false, (method, url, error) => {
      if (error.response.status >= 400 || error.response.status <= 499) {
        // In this case we ignore the default error handlers handling of 401 or 403
        // and let the LoginForm display a message about invalid login.
        throw error;
      }

      // Go to default error handler for all other errors.
      this.errorHandler(method, url, error);
    }).then(result => {
      if (result.data.token_type === TokenType.TWOFACTOR) {
        this.twoFactorToken = result.data.access_token;
      }
      return result;
    });
  }

  loginTwoFactorCode (code) {
    return this.POST('/gateway/services/auth-service/2fa/sms/verify', {
      code
    }, {
      'Authorization': `Bearer ${this.twoFactorToken}`
    });
  }

  loginSendTwoFactorCode () {
    return this.POST('/gateway/services/auth-service/2fa/sms/request', {}, {
      'Authorization': `Bearer ${this.twoFactorToken}`
    });
  }

  getMe () {
    return this.GET('/gateway/services/apiv2/portal_admin/me');
  }

  getPortalSettings () {
    return this.GET('/gateway/services/apiv2/portal_admin/settings');
  }

  getSites (page = 0, size = 2000) {
    return this.GET(`/gateway/services/patient-hub/sites/?page=${page}&size=${size}`);
  }

  async getSiteUsers (siteIds) {
    const response = await this.POST('/gateway/services/patient-hub/sites/users', siteIds);
    return response.data;
  }

  getSiteById (id) {
    return this.GET(`/gateway/services/patient-hub/sites/${id}`);
  }

  async searchSites (params) {
    const queryParams = [];
    appendFiltersToQueryParams(queryParams, params);

    let queryParamsString = queryParams.join('&');
    if (queryParamsString) {
      queryParamsString = `&${queryParamsString}`;
    }
    return await this.GET(`/gateway/services/patient-hub/api/sites?${queryParamsString}`);
  }

  getMyUsers () {
    return this.GET('/gateway/services/apiv2/portal_admin/my/users/');
  }

  postUser (body) {
    return this.POST('/gateway/services/apiv2/portal_admin/user/', body);
  }

  patchUser (user) {
    return this.PATCH('/gateway/services/apiv2/portal_admin/user/', user);
  }

  getUser (userId) {
    return this.GET(`/gateway/services/apiv2/portal_admin/user/${userId}`);
  }

  passwordReset (body) {
    // Don't use gateway here, apiv2 cannot properly serve this call via gateway
    return this.POST('/password/reset/', body);
  }

  forgotPasswordSendEmail (email) {
    // Don't use gateway here, apiv2 cannot properly serve this call via gateway
    const body = { email };
    return this.POST('/auth/password/reset/', body);
  }

  getProfileAggregatesBySiteId (siteId) {
    return this.GET(`/gateway/services/patient-hub/profiles/aggregates?site_id=${siteId}`);
  }

  getProfileById (profileId, bypassErrorDisplay = false) {
    return this.GET(`/gateway/services/patient-hub/api/profiles/${profileId}`, {}, bypassErrorDisplay);
  }

  async searchProfiles (params) {
    const queryParams = [];
    const { lastModifiedDate, page = 0, rows: size = 1000 } = params;
    if (lastModifiedDate) {
      queryParams.push(`lastModifiedDate.greaterThan=${startDate.toISOString()}`);
    }
    if (size) {
      queryParams.push(`size=${size}`);
    }
    if (page) {
      queryParams.push(`page=${page}`);
    }

    appendFiltersToQueryParams(queryParams, params);

    let queryParamsString = queryParams.join('&');
    if (queryParamsString) {
      queryParamsString = `&${queryParamsString}`;
    }
    return await this.GET(`/gateway/services/patient-hub/profiles/search?${queryParamsString}`);
  }

  async searchDetailedProfiles (params) {
    const queryParams = [];
    const { lastModifiedDate, page = 0, rows: size = 1000 } = params;
    if (lastModifiedDate) {
      queryParams.push(`lastModifiedDate.greaterThan=${startDate.toISOString()}`);
    }
    if (size) {
      queryParams.push(`size=${size}`);
    }
    if (page) {
      queryParams.push(`page=${page}`);
    }

    appendFiltersToQueryParams(queryParams, params);

    let queryParamsString = queryParams.join('&');
    if (queryParamsString) {
      queryParamsString = `&${queryParamsString}`;
    }

    return await this.GET(`/gateway/services/patient-hub/api/profiles?${queryParamsString}`);
  }

  async getSiteProfileDetails (site_profile_id) {
    const response = await this.GET(`/gateway/services/patient-hub/v2/sites/profiles/${site_profile_id}`)
    return response.data;
  }

  async updateProfileConsent (profileId, consents) {
    const response = await this.POST(`/gateway/services/patient-hub/v2/patient-consents/configure?profileId=${profileId}`, consents)
    return response.data;
  }

  //------------------------------------------------------------------------------------------------------------
  // Messages
  //------------------------------------------------------------------------------------------------------------

  getSMSTemplates (site) {
    return this.GET(`/gateway/services/communication-hub-service/api/v1/site-templates/${site.id}`);
  }

  async send ({ profileId, component = 'unknown-component', phoneNumber = undefined,
    replyEmail = undefined, emailSubject, emailBody, smsBody, appendDoNotReply = true }) {
    const body = {
      profile: profileId,
      source: `portal2.0:${component}`,
      message: {}
    };

    if (smsBody) {
      body.message_type = 'SMS'
      body.message.sms = {
        phone_number: phoneNumber !== '' && phoneNumber !== undefined ? phoneNumber : undefined, // Can be undefined, in which case the profile's phone number is used
        body: smsBody,
        append_do_not_reply: appendDoNotReply
      }
    }

    if (emailBody) {
      body.message_type = 'EMAIL'
      body.message.email = {
        reply_email: replyEmail,   // If undefined, 'NuvoAir no-reply@nuvoair.com' is used.
        subject: emailSubject,
        body: emailBody
      }
    }

    return this.POST(`/gateway/services/communication-hub-service/api/v1/communicationEngine/send/`, body);
  }

  postSendTemplate (site, body, title) {
    return this.POST('/gateway/services/communication-hub-service/api/v1/site-templates/', {
      tenant: site.id,
      body,
      title
    });
  }

  putSendTemplate (template_id, site, body, title) {
    return this.PUT(`/gateway/services/communication-hub-service/api/v1/site-templates/${template_id}`, {
      tenant: site.id,
      body,
      title
    });
  }

  deleteSendTemplate (template_id) {
    return this.DELETE(`/gateway/services/communication-hub-service/api/v1/site-templates/${template_id}`);
  }

  async getMessageChannels (params) {
    const { lastModifiedDate, size } = params;
    const queryParams = [];
    if (lastModifiedDate) {
      queryParams.push(`lastModifiedDate.greaterThan=${startDate.toISOString()}`);
    }
    if (size) {
      queryParams.push(`size=${size}`);
    }

    appendFiltersToQueryParams(queryParams, params);

    let queryParamsString = queryParams.join('&');
    if (queryParamsString) {
      queryParamsString = `&${queryParamsString}`;
    }
    const response = await this.GET(`/gateway/services/messaging-service/api/channels?${queryParamsString}`, {}, true);
    return response.data;
  }

  async getAllMessages (params) {
    const { size } = params;
    const queryParams = [];
    if (size) {
      queryParams.push(`size=${size}`);
    }
    
    appendFiltersToQueryParams(queryParams, params);
    
    const queryString = queryParams.join('&');
    const response = await this.GET(`/gateway/services/messaging-service/api/messages${queryString ? `?${queryString}` : ''}`, {}, true);
    return response;
  }

  async sendSmsMessage (principal, message) {
    const response = await this.POST(`/gateway/services/messaging-service/api/chat/${principal}`, message, { 'Content-Type': 'text/plain' }, true);
    return response.data;
  }

  async sendWebSocketMessage (messagePayload) {
    const response = await this.POST(`/gateway/services/messaging-service/api/sendMessage`, messagePayload, {}, true);
    return response.data;
  }

  getSiteProfileById (site_profile_id) {
    return this.GET(`/gateway/services/apiv2/sites/profiles/${site_profile_id}`)
  }

  generateSite ({ siteName, siteDescription, profileCount, profileType, shareCode,
    sessionCountMin, sessionCountMax, aosSessionCountMin, aosSessionCountMax }) {
    return this.POST('/gateway/services/apiv2/mock/site/', {
      name: siteName,
      description: siteDescription,
      profiles: {
        count: parseInt(profileCount),
        type: profileType,
        spirometry_sessions: {
          count: {
            min: sessionCountMin,
            max: sessionCountMax
          }
        },
        aos_sessions: {
          count: {
            min: aosSessionCountMin,
            max: aosSessionCountMax
          }
        }
      },
      share_code: shareCode,
      tag: '',
      source: 'portal:2.0'
    })
  }

  //------------------------------------------------------------------------------------------------------------
  // Patient Journey
  //------------------------------------------------------------------------------------------------------------

  async getPatientJourneyStatuses () {
    const response = await this.GET('/gateway/services/patient-hub/journey-statuses');
    return response.data;
  }

  async addPatientJourney (profileId, patientJourney) {
    const response = await this.POST(`/gateway/services/patient-hub/patient-journeys?profileId=${profileId}`, patientJourney);
    return response.data;
  }

  async deletePatientJourney (journeyId, profileId) {
    const response = await this.DELETE(`/gateway/services/patient-hub/patient-journeys/${journeyId}?profileId=${profileId}`);
    return response.data;
  }

  //------------------------------------------------------------------------------------------------------------
  // Video Call Stuff
  //------------------------------------------------------------------------------------------------------------
  getOrCreateTwilioVideoRoom (userIds, siteId) {
    return this.POST('/gateway/services/communication-hub-service/api/v1/twilio-video-conference/rooms/', {
      user_ids: userIds,
      site_id: siteId
    });
  }

  /**
   * Note this is used to get a host JWT *or* a patient JWT. The participant IDs are all returned
   * in getOrCreateTwilioVideoRoom.
   *
   * @param participantId
   * @returns {*}
   */
  createTwilioAccessTokenForParticipant (participantId) {
    return this.POST(`/gateway/services/communication-hub-service/api/v1/twilio-video-conference/rooms/participants/${participantId}/access_tokens/`);
  }

  sendTwilioVideoRoomInvite ({ participantId, sendSMS, sendEmail, customPhoneNumber = undefined, videoLinkUrl }) {
    return this.POST(`/gateway/services/communication-hub-service/api/v1/twilio-video-conference/rooms/participants/${participantId}/invites/`, {
      send_sms: sendSMS,
      send_email: sendEmail,
      phone_number: customPhoneNumber,
      video_link_url: videoLinkUrl
    });
  }

  getTwilioVideoParticipant (participantId) {
    return this.GET(`/gateway/services/communication-hub-service/api/v1/twilio-video-conference/rooms/participants/${participantId}`);
  }

  getTwilioVideoRoom (videoRoomUniqueName) {
    return this.GET(`/gateway/services/communication-hub-service/api/v1/twilio-video-conference/rooms/${videoRoomUniqueName}`);
  }

  getSpirometrySessionReport ({ spirometrySessionIds, reportName, siteId, format = 'json', blob = false }) {
    switch (reportName) {
      case 'spirometry_basic_report':
      case 'spirometry_premium_report':
      case 'spirometry_compare_report':
        // Legacy spirometry reports
        return this.GET(`/gateway/services/apiv2/v3/generate-report/?name=${reportName}&spirometry_session_ids=${spirometrySessionIds}&medications_field=medications_v2&site_id=${siteId}&format=${format}`, blob ? { responseType: 'blob' } : {});
      case 'spirometry_basic_report_ats2019':
      case 'spirometry_premium_report_ats2019':
        return this.GET(`/gateway/services/spirometry-service/api/care-portal/sessions/${spirometrySessionIds}/report.${format}/?name=${reportName}&site_id=${siteId}`, blob ? { responseType: 'blob' } : {});
      case 'spirometry_compare_report_ats2019':
        return this.GET(`/gateway/services/spirometry-service/api/care-portal/sessions/${spirometrySessionIds.join('/')}/comparison-report.${format}/?name=${reportName}&site_id=${siteId}`, blob ? { responseType: 'blob' } : {});
      default:
        throw new Error(`Unknown report name: ${reportName}`);
    }
  }

  downloadPatientReport (reportId, siteName, siteProfileId) {
    return this.GET(`/gateway/services/spirometry-service/api/care-portal/reports/${reportId}.pdf?site_name=${siteName}&siteprofile_id=${siteProfileId}`, { responseType: 'blob' });
  }

  getSpirometrySession (sessionID) {
    return this.GET(`/gateway/services/apiv2/spirometry_sessions/${sessionID}`);
  }

  //------------------------------------------------------------------------------------------------------------
  // Overwriting
  //------------------------------------------------------------------------------------------------------------

  getOverwriting (sessionID, overwritingID) {
    return this.GET(`/gateway/services/apiv2/spirometry/sessions/${sessionID}/overwritings/${overwritingID}`)
  }

  postOverwriting (overwriting) {
    return this.POST(`/gateway/services/apiv2/spirometry/sessions/${overwriting.sessionId}/overwritings/`, {
      occurred_at: new Date().toISOString(),
      created_by: overwriting.created_by,
      best_test: overwriting.bestTestId,
      comment: overwriting.comment ? overwriting.comment : null,
      excluded_test_ids: overwriting.excludedTestIds,
      fev1_selected_test: overwriting.fev1SelectedTestId ?? null, // null required by the backend, not undefined
      fvc_selected_test: overwriting.fvcSelectedTestId ?? null,
      pef_selected_test: overwriting.pefSelectedTestId ?? null
    });
  }

  deleteOverwriting (sessionId, overwritingId) {
    return this.DELETE(`/gateway/services/apiv2/spirometry/sessions/${sessionId}/overwritings/${overwritingId}`);
  }

  //------------------------------------------------------------------------------------------------------------
  // Asthma Report
  //------------------------------------------------------------------------------------------------------------
  /**
   * Post a single asthma report, sans id, to the backend to create a new report.
   *
   * @param asthmaReport
   * @returns {Promise<unknown>}
   */
  postAsthmaReport (asthmaReport) {
    if (asthmaReport.id) {
      throw new Error('postAsthmaReport was given an id. Use patchAsthmaReport.');
    }

    return this.POST(`/gateway/services/apiv2/reports/`, asthmaReportSerialize(asthmaReport));
  }

  patchAsthmaReport (asthmaReport) {
    if (!asthmaReport.id) {
      throw new Error('patchAsthmaReport: id was undefined');
    }

    return this.PATCH(`/gateway/services/apiv2/reports/${asthmaReport.id}`, asthmaReportPatchSerialize(asthmaReport));
  }

  getAsthmaReport (asthmaReportId) {
    return this.GET(`/gateway/services/apiv2/reports/${asthmaReportId}`);
  }

  getReportsByProfileId (profileId) {
    return this.GET(`/gateway/services/apiv2/reports/?profile_id=${profileId}&limit=100&offset=0&type=asthma`);
  }

  //------------------------------------------------------------------------------------------------------------
  // Customer communication permissions
  //------------------------------------------------------------------------------------------------------------

  getCustomerCommunicationPreferences (profileID) {
    return this.GET(`/gateway/services/apiv2/profiles/${profileID}/settings/communications`);
  }

  putCustomerCommunicationPreferences (profileID, communicationPreferences) {
    return this.PUT(`/gateway/services/apiv2/profiles/${profileID}/settings/communications`, communicationPreferences);
  }

  //------------------------------------------------------------------------------------------------------------
  // Goals
  //------------------------------------------------------------------------------------------------------------
  /**
   * Get/Post/Delete for patient goals
   *
   */
  getPatientGoalByProfileId (profileId) {
    return this.GET(`/gateway/services/patient-hub/goals/most-recent?profileId=${profileId}`);
  }

  postPatientGoal (profileId, goal) {
    return this.POST(`/gateway/services/patient-hub/goals?profileId=${profileId}`, {
      goal
    });
  }

  deletePatientGoal (goalId, profileId) {
    return this.DELETE(`/gateway/services/patient-hub/goals/${goalId}?profileId=${profileId}`);
  }

  //------------------------------------------------------------------------------------------------------------
  // Todos
  //------------------------------------------------------------------------------------------------------------
  async getTodosByProfileId (profileId) {
    const response = await this.GET(`/gateway/services/todos-service/api/todo-tasks?principal.equals=${profileId}`);
    return response.data;
  }

  //------------------------------------------------------------------------------------------------------------
  // Profile
  //------------------------------------------------------------------------------------------------------------
  getSiteProfileInfo (siteProfileId) {
    return this.GET(`/gateway/services/patient-hub/sites/profiles/${siteProfileId}`);
  }

  patchSiteProfile (profileId, changeObj) {
    return this.PATCH(`/gateway/services/patient-hub/site/profiles/${profileId}`, changeObj);
  }

  addNewProfileAddress (profileId, newAddress) {
    return this.PUT(`/gateway/services/patient-hub/profiles/${profileId}/add-address`, newAddress);
  }

  deleteProfileAddress (profileId, addressId) {
    return this.PUT(`/gateway/services/patient-hub/profiles/${profileId}/remove-address?addressId=${addressId}`);
  }

  updateProfileAddress (profileId, updatedAddress) {
    return this.PUT(`/gateway/services/patient-hub/profiles/${profileId}/update-address`, updatedAddress);
  }

  addNewProfilePhone (profileId, newPhone) {
    return this.PUT(`/gateway/services/patient-hub/profiles/${profileId}/add-phone`, newPhone);
  }

  deleteProfilePhone (profileId, phoneId) {
    return this.PUT(`/gateway/services/patient-hub/profiles/${profileId}/remove-phone?phoneId=${phoneId}`)
  }

  updateProfilePhone (profileId, updatedPhone) {
    return this.PUT(`/gateway/services/patient-hub/profiles/${profileId}/update-phone`, updatedPhone);
  }

  getAllConditions () {
    return this.GET(`/gateway/services/patient-hub/conditions`);
  }

  addNewProfileConditions (profileId, newConditions) {
    return this.PUT(`/gateway/services/patient-hub/profiles/${profileId}/add-conditions`, newConditions);
  }

  deleteProfileConditions (profileId, conditionNames) {
    return this.PUT(`/gateway/services/patient-hub/profiles/${profileId}/remove-conditions`, conditionNames);
  }

  getAssignedQuestionnaires (profileId) {
    return this.GET(`/gateway/services/questionnaire/api/v1/questionnaire-engine/assigned-questionnaires?principal=${profileId}`);
  }

  getQuestionnaireResults (profileId, questionnaireName) {
    return this.GET(`/gateway/services/questionnaire/api/v1/questionnaire-results/?principal.equals=${profileId}&questionnaireInternalName.equals=${questionnaireName}&size=1000`);
  }

  getAllQuestionnaireResults (profileId) {
    return this.GET(`/gateway/services/questionnaire/api/v1/questionnaire-results/?principal.equals=${profileId}&size=1000&sort=createdDate,desc`);
  }

  submitNpsQuestionnaire (profileId, payload) {
    return this.POST(`/gateway/services/patient-hub/nps-questionnaire/${profileId}`, payload);
  }

  getAllOrders (profileId) {
    return this.GET(`/gateway/services/patient-hub/api/physician-orders/?principal.equals=${profileId}&size=1000&sort=createdDate,desc`);
  }

  async getProfileLinks (ownerId) {
    const response = await this.GET(`/gateway/services/patient-hub/api/nuvoair-users/${ownerId}/links`);
    return response.data;
  }

  async getCommunicationMediums () {
    const response = await this.GET(`/gateway/services/patient-hub/communication-mediums`);
    return response.data;
  }

  async getConsentTemplates () {
    const response = await this.GET(`/gateway/services/consent-service/api/admin/consent-templates`);
    return response.data;
  }

  async getConsentDeliveryMethods () {
    const response = await this.GET(`/gateway/services/consent-service/api/admin/sign-now/invite-media-enum-values`);
    return response.data;
  }
  
  async createConsentDocument ({document, medium, language}) {
    await this.POST(`/gateway/services/consent-service/api/admin/sign-now/send-document-invite/${medium}/language/${language}`, document);
  }

  async getConsentDocument (documentId) {
    const response = await this.GET(`/gateway/services/consent-service/api/admin/sign-now/download-document/${documentId}`);
    return response.data;
  }

  async getConsentDocumentView (documentId) {
    const response = await this.GET_BLOB(`/gateway/services/consent-service/api/admin/sign-now/view-document/${documentId}.pdf`);
    return response.data;
  }

  //------------------------------------------------------------------------------------------------------------
  // Filters
  //------------------------------------------------------------------------------------------------------------
  createFilterView (siteUserId, filterView) {
    return this.PUT(`/gateway/services/patient-hub/site-users/${siteUserId}/add-filterview`, filterView);
  }

  updateFilterView (siteUserId, filterView) {
    return this.PUT(`/gateway/services/patient-hub/site-users/${siteUserId}/update-filterview`, filterView);
  }
  getFilterViews (siteUserId) {
    return this.GET(`/gateway/services/patient-hub/site-users/${siteUserId}/filterviews`);
  }

  deleteFilterView (siteUserId, filterViewId) {
    return this.PUT(`/gateway/services/patient-hub/site-users/${siteUserId}/remove-filterview?filterViewId=${filterViewId}`);
  }

  //------------------------------------------------------------------------------------------------------------
  // Communication Logs
  //------------------------------------------------------------------------------------------------------------

  async getCommunicationPurposes () {
    const response = await this.GET(`/gateway/services/patient-log/api/admin/purpose-enum-values?includeDescription=true`);
    return response.data;
  }

  async getCommunicationLogs ({ profileId, startDate, endDate }) {
    const queryParams = [];
    if (startDate) {
      queryParams.push(`sent.greaterThanOrEqual=${startDate}`);
    }
    if (endDate) {
      queryParams.push(`sent.lessThanOrEqual=${endDate}`);
    }
    if (profileId) {
      queryParams.push(`principal.equals=${profileId}`);
    }

    let queryParamsString = queryParams.join('&');
    if (queryParamsString) {
      queryParamsString = `&${queryParamsString}`;
    }
    const response = await this.GET(`/gateway/services/patient-log/api/admin/communications?${queryParamsString}&page=0&sort=createdDate,desc&size=10000`);
    return response.data;
  }
  async getCommunicationLogById (logId) {
    const response = await this.GET(`/gateway/services/patient-log/api/admin/communications/${logId}`);
    return response.data;
  }
  async getCommunicationLogByCallId (callId) {
    const response = await this.GET(`/gateway/services/patient-log/api/communications/zoom-phone-log/${callId}`);
    return response.data;
  }
  async addCommunicationLog (profileId, communicationLog) {
    const response = await this.POST(`/gateway/services/patient-hub/communications?profileId=${profileId}`, communicationLog);
    return response.data;
  }
  async addCommunicationLogNote (communicationLogId, note) {
    const response = await this.POST(`/gateway/services/patient-log/api/communications/${communicationLogId}/add-note`, note);
    return response.data;
  }
  async updateCommunicationLog (communicationLog) {
    const response = await this.PATCH(`/gateway/services/patient-log/api/admin/communications/${communicationLog.id}`, communicationLog);
    return response.data;
  }
  async updateCommunicationLogNote (noteId, note) {
    const response = await this.PATCH(`/gateway/services/patient-log/api/admin/notes/${noteId}`, note);
    return response.data;
  }
  async deleteCommunicationLogNote (noteId) {
    const response = await this.DELETE(`/gateway/services/patient-log/api/admin/notes/${noteId}`);
    return response.data;
  }

  //------------------------------------------------------------------------------------------------------------
  // Appointments
  //------------------------------------------------------------------------------------------------------------

  async getAppointments (params) {
    const { profileId, startDate, endDate, page, rows, filters } = params;
    const queryParams = [`page=${page || 0}`, `size=${rows || 5}`];
    if (startDate) {
      queryParams.push(`startTime.greaterThanOrEqual=${startDate.toISOString()}`);
    }
    if (endDate) {
      queryParams.push(`startTime.lessThanOrEqual=${endDate.toISOString()}`);
    }
    if (profileId) {
      queryParams.push(`principal.equals=${profileId}`);
    }

    appendFiltersToQueryParams(queryParams, params);

    let queryParamsString = queryParams.join('&');
    if (queryParamsString) {
      queryParamsString = `&${queryParamsString}`;
    }
    const response = await this.GET(`/gateway/services/patient-log/api/admin/appointments?${queryParamsString}&includePii=true`);
    return response;
  }
  async addAppointment (profileId, appointment) {
    const response = await this.POST(`/gateway/services/patient-hub/appointments?profileId=${profileId}&includePii=true`, appointment);
    return response.data;
  }
  async updateAppointment (appointmentId, appointment) {
    const response = await this.PATCH(`/gateway/services/patient-log/api/admin/appointments/${appointmentId}?includePii=true`, appointment);
    return response.data;
  }
  async addAppointmentNote (appointmentId, note) {
    const response = await this.POST(`/gateway/services/patient-log/api/appointments/${appointmentId}/add-note`, note);
    return response.data;
  }
  async updateAppointmentNote (noteId, note) {
    const response = await this.PATCH(`/gateway/services/patient-log/api/admin/notes/${noteId}`, note);
    return response.data;
  }
  async deleteAppointmentNote (noteId) {
    const response = await this.DELETE(`/gateway/services/patient-log/api/admin/notes/${noteId}`);
    return response.data;
  }
  async addAppointmentToAdverseEvent (adverseEventId, appointment) {
    const response = await this.POST(`/gateway/services/patient-log/api/adverse-events/${adverseEventId}/add-appointment?includePii=true`, appointment);
    return response.data;
  }
  //   const apiUrl = 'services/patient-log/api/admin/appointment-booking-links';

  // export const getEntities = createAsyncThunk('appointmentBookingLink/fetch_entity_list', async ({ query, page, size, sort }: IQueryParams) => {
  //   const requestUrl = `${apiUrl}${sort ? `?page=${page}&size=${size}&sort=${sort}&${query}&` : '?'}cacheBuster=${new Date().getTime()}`;
  //   return axios.get<IAppointmentBookingLink[]>(requestUrl);
  // });

  async getAppointmentCareTeamBookingLinks () {
    const response = await this.GET('/gateway/services/patient-log/api/admin/appointment-booking-links?size=1000&includePii=true');
    return response.data;
  }

  // gateway/services/patient-hub/api/site-user-roles?careTeam.equals=true

  async getCareTeamSiteUserRoles () {
    const response = await this.GET('/gateway/services/patient-hub/api/site-user-roles?careTeam.equals=true&visibleInPortal.equals=true');
    return response.data;
  }

  async getCareTeamForProfile (profileId) {
    const response = await this.GET(`/gateway/services/patient-hub/api/nuvoair-team?profileId=${profileId}`);
    return response.data;
  }

  async getTeamMembersForSite (siteId) {
    const response = await this.GET(`/gateway/services/patient-hub/api/nuvoair-team?siteId=${siteId}`);
    return response.data
  }

  async updateTeamMembersForProfile (profileId, careTeam) {
    let payload = {
      profileId: profileId,
      careTeam: careTeam
    }
    const response = await this.POST(`/gateway/services/patient-hub/api/nuvoair-team`, payload);
    return response.data;
  }



  //------------------------------------------------------------------------------------------------------------
  // Care Events
  //------------------------------------------------------------------------------------------------------------
  async getAdverseEventTypes () {
    const response = await this.GET(`/gateway/services/patient-log/api/admin/adverse-event-types`);
    return response.data;
  }
  async getAdverseEvents (params) {
    const { profileId, startDate, endDate, actuality, page, rows } = params;
    const queryParams = [`page=${page || 0}`, `size=${rows || 5}`];

    if (startDate) {
      queryParams.push(`startTime.greaterThanOrEqual=${startDate.toISOString()}`);
    }
    if (endDate) {
      queryParams.push(`startTime.lessThanOrEqual=${endDate.toISOString()}`);
    }
    if (profileId) {
      queryParams.push(`principal.equals=${profileId}`);
    }
    if (actuality) {
      queryParams.push(`actuality.equals=${actuality}`);
    }

    appendFiltersToQueryParams(queryParams, params);

    let queryParamsString = queryParams.join('&');
    if (queryParamsString) {
      queryParamsString = `&${queryParamsString}`;
    }

    const response = await this.GET(`/gateway/services/patient-log/api/admin/adverse-events?${queryParamsString}&includePii=true`);
    return response;
  }
  async addAdverseEvent (profileId, adverseEvent) {
    const response = await this.POST(`/gateway/services/patient-hub/adverse-events?profileId=${profileId}&includePii=true`, adverseEvent);
    return response.data;
  }
  async updateAdverseEvent (adverseEventId, adverseEvent) {
    const response = await this.PATCH(`/gateway/services/patient-log/api/admin/adverse-events/${adverseEventId}?includePii=true`, adverseEvent);
    return response.data;
  }
  async addAdverseEventNote (adverseEventId, note) {
    const response = await this.POST(`/gateway/services/patient-log/api/adverse-events/${adverseEventId}/add-note`, note);
    return response.data;
  }
  async updateAdverseEventNote (noteId, note) {
    const response = await this.PATCH(`/gateway/services/patient-log/api/admin/notes/${noteId}`, note);
    return response.data;
  }
  async deleteAdverseEventNote (noteId) {
    const response = await this.DELETE(`/gateway/services/patient-log/api/admin/notes/${noteId}`);
    return response.data;
  }

  //------------------------------------------------------------------------------------------------------------
  // Today
  //------------------------------------------------------------------------------------------------------------

  async getTodayItems (params) {
    const queryParams = [];
    appendFiltersToQueryParams(queryParams, params);
    let queryParamsString = queryParams.join('&');
    if (queryParamsString) {
      queryParamsString = `&${queryParamsString}`;
    }
    const response = await this.GET(`/gateway/services/patient-log/api/today?${queryParamsString}&includePii=true`);
    return response;
  }

  // ------------------------------------------------------------------------------------------------------------
  // Devices
  // ------------------------------------------------------------------------------------------------------------
  async getProfileDevices (profileId) {
    const response = await this.GET(`/gateway/services/device-manager/api/admin/device-owners?principal.equals=${profileId}`);
    return response.data;
  }
  async getAvailableDevices () {
    const response = await this.GET(`/gateway/services/device-manager/api/admin/devices?shippingCode.specified=true`);
    return response.data;
  }
  async cancelDeviceOrder (deviceOrderId) {
    const response = await this.PUT(`/gateway/services/device-manager/orders/${deviceOrderId}/cancel-order`);
    return response.data;
  }
  async submitDeviceOrder (profileId, order) {
    const response = await this.POST(`/gateway/services/patient-hub/orders?profileId=${profileId}`, order);
    return response.data;
  }

  async getAppointmentScheduleLink (siteId) {
    const response = await this.GET(`/gateway/services/patient-log/api/v1/appointment-schedule/${siteId}`);
    return response.data;
  }

  async createAppointmentScheduleLink (siteId, appointmentScheduleLink) {
    const response = await this.POST(`/gateway/services/patient-log/api/v1/appointment-schedule`, {
      tenant: siteId,
      ...appointmentScheduleLink
    });
    return response.data;
  }

  async updateAppointmentScheduleLink (appointmentId, appointmentScheduleLink) {
    const response = await this.PUT(`/gateway/services/patient-log/api/v1/appointment-schedule/${appointmentId}`, appointmentScheduleLink);
    return response.data;
  }

  async deleteAppointmentScheduleLink (appointmentId) {
    const response = await this.DELETE(`/gateway/services/patient-log/api/v1/appointment-schedule/${appointmentId}`);
    return response.data;
  }

  // ------------------------------------------------------------------------------------------------------------
  // Practitioners
  // ------------------------------------------------------------------------------------------------------------
  async searchPractitioners (params) {
    const queryParams = [];
    const { lastModifiedDate, size = 1000 } = params;
    if (lastModifiedDate) {
      queryParams.push(`lastModifiedDate.greaterThan=${startDate.toISOString()}`);
    }
    queryParams.push(`size=${size}`);

    appendFiltersToQueryParams(queryParams, params);

    let queryParamsString = queryParams.join('&');
    if (queryParamsString) {
      queryParamsString = `&${queryParamsString}`;
    }
    return await this.GET(`/gateway/services/patient-hub/api/admin/practitioners/individual?${queryParamsString}`);
  }

  async searchNpiRegistry (queryParams) {
    let queryParamsString = queryParams.join('&');
    return await this.GET(`/gateway/services/patient-hub/api/admin/practitioners/fetch?${queryParamsString}&limit=100`);
  }

  async assignPractitionersToSite ({ siteId, practitioners }) {
    return await this.PUT(`/gateway/services/patient-hub/sites/${siteId}/add-practitioners`, { practitioners })
  }

  async getPractitionersBySite (siteId) {
    return await this.GET(`/gateway/services/patient-hub/sites/${siteId}/practitioners`);
  }

  // Fetches the practitioner. If the practitioner does not exist in the database, the api creates the practitioner.
  async fetchPractitioner (npi) {
    return await this.GET(`/gateway/services/patient-hub/api/admin/practitioners/fetch/${npi}`)
  }

  async getSpirometrySessionById(sessionId) {
    return this.GET(`/gateway/services/spirometry-service/api/care-portal/sessions/${sessionId}`);
  }

  async addSpirometrySessionOverwrite(sessionId, overwrite) {
    return this.PATCH(`/gateway/services/spirometry-service/api/care-portal/sessions/${sessionId}/overwrite`, overwrite);
  }
  
  async assignPractitionerToProfile({profileId, npi, type}) {
    return await this.POST(`/gateway/services/patient-hub/api/profiles/${profileId}/add-practitioner/${npi}?type=${type}`, {});
  }

  async unassignPractitionersFromProfile({profileId, practitioners}) {
    return await this.PUT(`/gateway/services/patient-hub/api/profiles/${profileId}/remove-practitioners`, {practitioners});
  }

  async getAppointmentCancellationReasons() {
    const response = await this.GET('/gateway/services/patient-log/api/appointments/cancellation-reasons');
    return response.data;
  }

  async cancelAppointmentWithReason(appointmentId, reason) {
    const response = await this.POST(`/gateway/services/patient-log/api/appointments/${appointmentId}/cancel?reason=${reason}`);
    return response.data;
  }
  // ------------------------------------------------------------------------------------------------------------
  // Athena Integrations
  // ------------------------------------------------------------------------------------------------------------

  async getIsAthenaIntegrationEnabled () {
    return this.GET(`/gateway/services/patient-hub/nuvoair/athena/enabled`);
  }

  async getAthenaProviders () {
    return this.GET(`/gateway/services/patient-hub/nuvoair/ehr-providers`);
  }

  async uploadAndAssignEHRDocument (
      profileId,
      providerId,
      document,
      autoClose = false,
      internalNote = 'NuvoAir Spirometry Report',
  ) {
    const form = new FormData();
    if (providerId !== null) {
      form.append('providerId', providerId);
    }
    form.append('attachmentContents', document, 'report.pdf');
    form.append('attachmentType', 'PDF')
    form.append('autoClose', autoClose);
    form.append('internalNote', internalNote);
    return this.POST(
        `/gateway/services/patient-hub/profiles/${profileId}/documents/imagingresult`,
        form,
        { 'Content-Type': 'multipart/form-data' }
    );
  }

  async getStickyNote(profileId, size = 1, sort = 'lastModifiedDate,desc') {
    const response = await this.GET(`/gateway/services/patient-log/api/admin/notes?principal.equals=${profileId}&subject.equals=StickyNote&sort=${sort}&size=${size}&includePii=true`);
    return response.data;
  }

  async addStickyNote(profileId, text, priority = 'HIGH') {
    const response = await this.POST(`/gateway/services/patient-hub/notes?profileId=${profileId}`, {
      priority: priority,
      subject: "StickyNote",
      text: text
    });
    return response.data;
  }

  async updateStickyNote(noteId, text, priority = 'HIGH') {
    const response = await this.PATCH(`/gateway/services/patient-log/api/admin/notes/${noteId}`, {
      id: noteId,
      text: text,
      priority: priority
    });
    return response.data;
  }

};



function appendFiltersToQueryParams (queryParams, filtersObj) {
  const { sortField, sortOrder, multiSortMeta, filters } = filtersObj;

  // Sorting
  if (sortField) {
    queryParams.push(`sort=${sortField},${sortOrder === 1 ? 'asc' : 'desc'}`);
  }

  // Multi-sort
  if (multiSortMeta && multiSortMeta.length > 0) {
    multiSortMeta.forEach(sortMeta => {
      queryParams.push(`sort=${sortMeta.field},${sortMeta.order === 1 ? 'asc' : 'desc'}`);
    });
  }

  // Filters
  if (filters) {
    Object.keys(filters).forEach(key => {
      const filter = filters[key];

      // Check if the filter uses constraints
      if (filter.constraints && filter.constraints.length > 0) {
        // Extract all values for 'EQUALS' matchMode
        const equalValues = filter.constraints
          .filter(constraint => constraint.matchMode === FilterMatchMode.EQUALS && constraint.value)
          .map(constraint => encodeURIComponent(constraint.value));

        // Concatenate these values and append as a single query parameter
        if (equalValues.length > 0) {
          queryParams.push(`${key}.in=${equalValues.join(',')}`);
        }

      } else if (filter.matchMode == FilterMatchMode.EQUALS && (filter.value != null || filter.checkNull)) {
        queryParams.push(`${key}.equals=${encodeURIComponent(filter.value)}`);
      } else if (filter.matchMode == FilterMatchMode.NOT_EQUALS && (filter.value != null || filter.checkNull)) {
        queryParams.push(`${key}.notEquals=${encodeURIComponent(filter.value)}`);
      } else if (filter.matchMode == FilterMatchMode.CONTAINS && (filter.value != null || filter.checkNull)) {
        queryParams.push(`${key}.contains=${encodeURIComponent(filter.value)}`);
      } else if (filter.matchMode == FilterMatchMode.GREATER_THAN && (filter.value != null || filter.checkNull)) {
        queryParams.push(`${key}.greaterThan=${encodeURIComponent(filter.value)}`);
      } else if (filter.matchMode == FilterMatchMode.LESS_THAN && (filter.value != null || filter.checkNull)) {
        queryParams.push(`${key}.lessThan=${encodeURIComponent(filter.value)}`);
      } else if (filter.matchMode && (filter.value != null || filter.checkNull)) {
        queryParams.push(`${key}.${filter.matchMode}=${encodeURIComponent(filter.value)}`);
      }
    });
  }


}
