<template>
  <ProgressSpinner class="mt-4" style="width: 50px; height: 50px" v-if="loading" />
  <div v-else :class="`flex vertical full-height overflow-hidden ${isMaximized ? 'chat-channel pa-6 pt-5 pr-0' : ''}`">
    <div
      :class="`flex align-items-center flex-initial text-3xl max-w-full ${isMaximized ? 'chat-channel-header flex-row-reverse justify-content-end' : 'mt-3'}`">
      <span class="mr-3">{{ patientFullName || 'Unknown' }}</span>
      <img class="profile-details-avatar mr-3" src="@/assets/img/profile.png" alt="">
    </div>
    <span :class="`${isManagedByMe ? 'user-managed' : ''}`">{{ channelOwnerText }}</span>
    
    <InlineMessage v-if="fetchError" class="flex flex-grow-0 justify-content-start align-items-center mt-3" severity="error">{{fetchError.message || 'Chat service has encountered an error'}}</InlineMessage>
    
    <div class="chat-thread-container">
      <div class="flex flex-column overflow-hidden">
        <div class="phi-warning">
          <i class="pi pi-info-circle"></i>
          <span>To safeguard patient privacy, do not use SMS for sharing PHI.</span>
        </div>
        <div class="chat-message-thread">
          <div class="flex flex-column flex-grow-0" v-for="(item, index) in threadItems" :key="index">
            <span v-if="item.type === 'separator'" class="start-message">{{ item.text }}</span>
            <span v-else-if="item.type === 'error'" class="send-error"> {{ item.text }}</span>
            <span v-else-if="item.type === 'loading'" class="flex flex-row mr-3 mb-3 align-self-end">
              <ProgressSpinner style="width: 16px; height: 16px" />
            </span>
            <ChatSidebarMessageThreadItem v-else :channel="channel" :message="item" />
          </div>
          <span v-if="!hasEarlierMessages" class="start-message">If you think you may be experiencing a life threatening emergency.
            We recommend you immediately call 911 for medical assistance, or go to a nearby emergency room.</span>
          <span v-if="!hasEarlierMessages" class="start-message">Please allow 1 business day for our team to get back to you.</span>
          <span v-if="!hasEarlierMessages" class="start-message">Start of Conversation</span>
          <ProgressSpinner v-if="loadingAdditionalMessages" class="mt-4 align-self-center" style="width: 24px; height: 24px" />
        </div>
        <div class="chat-submission">
          <Textarea v-model="draftMessage" :autoResize="true" placeholder="Message" rows="1" cols="30" />
          {{ sendAs.label }}
          <SplitButton :class="`send-button p-button p-button-sm pa-0`"
            label="Send" icon="pi pi-send" @click="message_submitHandler" :model="sendAsOptions"
            buttonClass="dropdown-button" />
        </div>
      </div>
    </div>
  </div>
</template>
  
<script>

import moment from 'moment';
import { setupScrollDetection } from "@/helpers";
import ChatSidebarMessageThreadItem from "@/components/layout/sidebar/sidebar-panels/chat/ChatSidebarMessageThreadItem";
import { ref, computed, onMounted, onUnmounted, watch } from '@vue/composition-api';

export default {
  components: {
    ChatSidebarMessageThreadItem,
  },
  props: {
    principal: {
      type: String,
      required: true,
    },
    maximized: {
      type: Boolean,
      default: false,
    },
  },
  setup (props, { root }) {
    const channelPrincipal = computed(() => props.principal);
    const isMaximized = computed(() => props.maximized);

    const channel = ref(null);
    const profile = ref(null);
    const draftMessage = ref('');
    const sendAs = ref({label: 'SMS', value: 'Sms'});
    const loading = ref(true);
    const loadingAdditionalMessages = ref(false);
    const sendingMessage = ref(false);
    const interval = ref(null);

    const sendAsOptions = [
      {
        label: 'SMS',
        command: () => {
          sendAs.value = {label: 'SMS', value: 'Sms'};
        },
      },
    ]

    if (root.$config.ENV_NAME && root.$config.ENV_NAME !== 'production') {
      sendAsOptions.push({
        label: 'App',
        command: () => {
          sendAs.value = {label: 'APP', value: 'InApp'};
        },
      })
    }

    // Store State and Getters
    const channels = computed(() => root.$store.state.messaging.channels);
    const fetchError = computed(() => root.$store.state.messaging.fetchError);
    const sendErrors = computed(() => root.$store.state.messaging.sendErrors);
    const getMessagesByChannelId = computed(() => root.$store.getters['messaging/getMessagesByChannelId']);
    const hasEarlierMessagesByChannelId = computed(() => root.$store.getters['messaging/hasEarlierMessagesByChannelId']);
    const expanded = computed(() => root.$store.state.sidebar.expanded);
    const userId = computed(() => root.$store.getters['portal/userId']);

    const messages = computed(() => getMessagesByChannelId.value(channel.value?.id) || []);
    const threadItems = computed(() => {
      const items = addDateSeparators(messages.value);
      if (sendError.value) {
        items.unshift({
          type: 'error',
          text: sendError.value,
        })
      }
      if (sendingMessage.value) {
        items.unshift({
          type: 'loading',
          text: 'Sending...',
        })
      }
      return items;
    })

    const isManagedByMe = computed(() => channel.value?.owner === userId.value);
    const patientFullName = computed(() => {
      return channel.value?.patientFullName || (profile.value ? (profile.value.firstName || "") + " " + (profile.value.lastName || "") : "");
    })

    const channelOwnerText = computed(() => {
      if (!channel.value?.owner) {
        return 'Managed by: No one';
      } else if (channel.value?.owner !== userId.value) {
        return `Managed by: ${channel.value?.ownerPii?.firstName || ''} ${channel.value?.ownerPii?.lastName || ''}`;
      } else {
        return 'Managed by you'
      }
    })

    const hasEarlierMessages = computed(() => channel.value && hasEarlierMessagesByChannelId.value(channel.value.id));
    const sendError = computed(() => sendErrors.value[channelPrincipal.value]);

    const sendSmsMessage = (payload) => root.$store.dispatch('messaging/sendSmsMessage', payload);
    const sendWebSocketMessage = (payload) => root.$store.dispatch('messaging/sendWebSocketMessage', payload);
    const fetchMessagesForChannel = (payload) => root.$store.dispatch('messaging/fetchMessagesForChannel', payload);
    const fetchLatestMessagesForChannel = (payload) => root.$store.dispatch('messaging/fetchLatestMessagesForChannel', payload);
    const fetchChannelByPrincipal = (payload) => root.$store.dispatch('messaging/fetchChannelByPrincipal', payload);

    const loadChannel = async () => {
      if (!channelPrincipal.value) {
        // ? Where is this being caught?
        throw new Error('A principal is required to load chat thread');
      }
      loading.value = true;
      channel.value = await fetchChannelByPrincipal(channelPrincipal.value);
      if (channel.value) {
        await fetchMessagesForChannel({ channelId: channel.value.id });
      } else {
        await loadProfile(); // If no channel exists yet for principal, fetch patient info (i.e. name)
      }
      loading.value = false;
      setTimeout(() => {
        setupScrollDetection('.chat-message-thread', 'show-scrollbar');
        setupInfiniteScrolling();
      }, 200);
    }
    
    const setupInfiniteScrolling = () => {
      const scrollContainer = document.querySelector('.chat-message-thread');
      scrollContainer && scrollContainer.addEventListener('scroll', async () => {
        const { scrollTop, scrollHeight, clientHeight } = scrollContainer;
        if (Math.abs(scrollTop) + 4 > (scrollHeight - clientHeight)) {
          if (hasEarlierMessages.value && !loadingAdditionalMessages.value) {
            loadingAdditionalMessages.value = true;
            await fetchMessagesForChannel({ channelId: channel.value.id });
            loadingAdditionalMessages.value = false;
          }
        }
      })
    }

    const loadProfile = async () => {
      const response = await root.$apiv2.getProfileById(channelPrincipal.value);
      profile.value = response?.data || null;
    }

    const sendMessageSms = async () => {
      const sentMessage = await sendSmsMessage({
        principal: channelPrincipal.value,
        message: draftMessage.value,
      });
      if (sentMessage) {
        draftMessage.value = '';
        channel.value = sentMessage.channel;
      }
    }

    const sendMessageWebSocket = async () => {
      const sentMessage = await sendWebSocketMessage({
        principal: channelPrincipal.value,
        message: draftMessage.value,
        medium: sendAs.value.value,
        clinicianId: userId.value,
      });
      if (sentMessage) {
        draftMessage.value = '';
        if (!channel.value?.id) {
          channel.value = {id: sentMessage.channelId}
        }
      }
    }
    const message_submitHandler = async () => {
      if (!draftMessage.value) {
        return;
      }
      sendingMessage.value = true;
      if (sendAs.value?.value === 'Sms') {
        await sendMessageSms();
      } else if (sendAs.value?.value === 'InApp') {
        await sendMessageWebSocket();
      }
      sendingMessage.value = false;
    }

    const addDateSeparators = (sortedMessageList) => {
      // Assumes it is provided a list of messages sorted from newest to oldest.
      let startOfNextTimePeriod = null;
      return sortedMessageList.reduce((acc, msg, ix) => {
        const msgCreatedDate = moment(msg.createdDate);
        if (shouldStartNewTimePeriod(msgCreatedDate, startOfNextTimePeriod)) {
          if (startOfNextTimePeriod) {
            acc.push({
              type: 'separator',
              text: startOfNextTimePeriod.calendar(null, {
                sameElse: 'MM/DD/YYYY [at] LT',
              }),
            });
          }
          startOfNextTimePeriod = msgCreatedDate;
        }
        acc.push(msg);
        if (ix === sortedMessageList.length - 1) {
          acc.push({
            type: 'separator',
            text: msgCreatedDate.calendar(null, {
              sameElse: 'MM/DD/YYYY [at] LT',
            }),
          });
        }
        return acc;
      }, []);
    }

    const shouldStartNewTimePeriod = (date1, date2) => {
      return !date1 || !date2 || date2.diff(date1, 'hours') >= 1;
    }

    const setupChatAutoRefresh = () => {
      interval.value = setInterval(() => {
        if (!channel.value?.id || !expanded.value) { return }
        fetchLatestMessagesForChannel(
          {
            channelId: channel.value.id,
          }
        );
      }, 5000);
    }

    const tearDownAutoRefresh = () => {
      clearInterval(interval.value);
    }

    onMounted(() => {
      loadChannel();
      setupChatAutoRefresh();
    })

    onUnmounted(() => {
      tearDownAutoRefresh();
    })

    watch(channelPrincipal, () => {
      loadChannel();
    })

    watch(channels, (updatedChannels) => {
      // Keeps the channel data updated
      if (!channel.value?.id) { return };
      const updatedChannel = updatedChannels.find(updatedChannel => updatedChannel.id === channel.value.id);
      if (updatedChannel) {
        channel.value = updatedChannel;
      };
    })

    return {
      isMaximized,
      channel,
      draftMessage,
      sendAs,
      loading,
      loadingAdditionalMessages,
      sendAsOptions,
      fetchError,
      threadItems,
      isManagedByMe,
      patientFullName,
      channelOwnerText,
      hasEarlierMessages,
      fetchChannelByPrincipal, 
      loadChannel,
      setupInfiniteScrolling,
      loadProfile,
      sendMessageSms,
      sendMessageWebSocket,
      message_submitHandler,
      addDateSeparators,
      shouldStartNewTimePeriod,
    }
  },
}
</script>
<style lang="scss" scoped>
::v-deep .p-tabview {
  .p-tabview-panels {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    overflow: hidden;
    padding: 0;
  }
}

.p-tabview,
.p-tabview-panel {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  height: auto;
  overflow: hidden;
}

.chat-channel {
  width: 70%;

  .chat-channel-header {
    display: flex;
    align-items: center;
    margin-top: 1px;
    margin-bottom: 13px;
  }
}

.profile-details-avatar {
  width: 32px;
  height: 32px;
}

.chat-thread-container {
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow: hidden;
}

.chat-message-thread {
  display: flex;
  flex-direction: column-reverse;
  flex: 1;
  overflow-y: auto;
  padding-right: 4px;
}

.send-error {
  margin: 2px 0;
  padding: 4px 10px;
  border-radius: 12px;
  width: fit-content;
  max-width: 280px;
  background-color: #f18787;
  color: white;
}

.phi-warning {
  display: flex;
  flex-direction: row;
  margin-top: 4px;
  padding: 4px 10px;
  align-items: center;
  background-color: #F5F5F5;
  border: 0.001px dashed #696CFF;
  color: #204877;
}

::v-deep .pi {
  margin-right: 6px;
}

.start-message {
  margin: 6px;
  align-self: center;
}

.user-managed {
  width: fit-content;
  background-color: #0073e6BB;
  color: white;
  border-radius: 6px;
  padding: 2px 6px;
}

::-webkit-scrollbar {
  width: 4px;
}

::-webkit-scrollbar-track {
  background: transparent;
}

.show-scrollbar::-webkit-scrollbar-track {
  background: #f1f1f1;
}

::-webkit-scrollbar-thumb {
  background: transparent;
  border-radius: 4px;
}

.show-scrollbar::-webkit-scrollbar-thumb {
  background: #609af8;
}

.chat-submission {
  padding-top: 12px;
  border-top: 1px solid #DEE2E6;
  display: flex;
  flex-direction: column;

  .send-button {
    align-self: flex-end;
    overflow: visible;

    &.disabled {
      opacity: 0.6;
    }

    > :disabled {
      opacity: 0.83;
      color: #FFF !important;
    }
  }

  .dropdown-button {
    top: -88px;
  }
}

.owner-enter-active {
  transition: background-color 1s;
}

.owner-leave-active {
  display: none;
}

.owner-enter,
.owner-leave-to {
  background-color: #99CCFF;
}
</style>